import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation/navigation";
import { Home } from "./components/home/home";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Entidades } from "./components/entidades/entidades";
import { Comercios } from "./components/comercios/comercios";

import { Services } from "./components/services";
import { Promociones } from "./components/promociones/promociones"
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Home data={landingPageData.Header} />
      {/*<Features data={landingPageData.Features} />*/}
      {/*<About data={landingPageData.About} />*/}
      {/*<Services data={landingPageData.Services} />*/}
      <Promociones data={landingPageData.Gallery} />
      <Entidades data={landingPageData.Header} />
      <Comercios data={landingPageData.Header} />

      {/*<Testimonials data={landingPageData.Testimonials} />*/}
      {/*<Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
