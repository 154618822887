import './comercios.css';

export const Comercios = (props) => {
  return (
    <header id='comercios'>
      <div className='comerciosintro'>
        <div className='overlaycomercios'>
          <div className='containercomercios'>
            <div className='titulocomercios'>
              <h2>Comercios</h2>
              {/*<p>
                Acceso a comercios
  </p>*/}
            </div>
            <div className='rowcomercios'>

              <div id="botonCentradoAzul">
                <div id="botonCentradoAzul2">
                  Tutoriales
                </div>
                <div id="botonCentradoAzul3">
                  Acceda a los tutoriales.
                </div>

              </div>

              <div id="botonCentradoAzul">
                <div id="botonCentradoAzul2">
                  QR
                </div>
                <div id="botonCentradoAzul3">
                  Acceda al Portal de QR.
                </div>
              </div>

              <div id="botonCentradoAzul">
                <div id="botonCentradoAzul2">
                  Portal
                </div>
                <div id="botonCentradoAzul3">
                  Acceda al Portal de Comercios.
                </div>
              </div>

              {/*<h1> obarios
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  {/* <a
                    href='#features'
                    className='btn btn-custom btn-lg page-scroll'
                  >
                    Learn More
                  </a>{' '}*/}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
