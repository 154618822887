import './entidades.css';

export const Entidades = (props) => {
  return (
    <header id='entidades'>
      <div className='entidadesintro'>
        <div className='overlayEntidades'>
          <div className='containerEntidades'>
            <div className='tituloEntidades'>
              <h2>Entidades</h2>
              {/*<p>
                Acceso a entidades
  </p>*/}
            </div>
            <div className='rowEntidades'>
              <div id="botonCentradoAzulIzquierda">
                <div id="botonCentradoAzulIzquierda2">
                  @cceso Cabal
                </div>
                <div id="botonCentradoAzulIzquierda3">
                  Acceso para la gestión del emisor de tarjetas Cabal.
                </div>

              </div>

              <div id="botonCentradoAzulDerecha">
                <div id="botonCentradoAzulDerecha2">
                  Entidad Emisora
                </div>
                <div id="botonCentradoAzulDerecha3">
                  Conocé aquí como vincularte como Entidad Emisora.
                </div>

              </div>

              {/*<h1> obarios
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  {/* <a
                    href='#features'
                    className='btn btn-custom btn-lg page-scroll'
                  >
                    Learn More
                  </a>{' '}*/}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
