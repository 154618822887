import { Image } from "./image";
import './promociones.css';
import pic1 from './img/01-large.jpg';
import pic2 from './img/01-small.jpg';

export const Promociones = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className=''>
          <h2>Promociones</h2>
          <p>
            Cabal te da un mundo de beneficios
          </p>
        </div>
        <div className='row2'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={pic1} smallImage={pic2} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
